'use client'

import { useEffect } from 'react'
import { usePathname, useSearchParams } from 'next/navigation'
import { pageView } from '@/utils/google'

function GoogleAnalyticsTracking() {
  const pathname = usePathname()
  const searchParams = useSearchParams()

  useEffect(() => {
    if (pathname) {
      const search = searchParams.toString()
      pageView(`${pathname}${search ? `?${search}` : ''}`)
    }
  }, [pathname, searchParams])

  return null
}

export default GoogleAnalyticsTracking
